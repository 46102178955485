@use '../constants' as *;

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $bgLighter;
  padding: 30px;
  gap: 15px;

  .top {
    display: flex;
    align-items: center;
    gap: 5px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      width: 35px;
      border-radius: 25%;
      border: 1px solid transparent;

      transition: all 0.25s linear;

      svg {
        color: white;
        font-size: 25px;
      }

      &:hover {
        cursor: pointer;
        border-radius: 50%;
        border: 1px solid white;
      }
    }
  }

  .bottom {
    p {
      font-size: $bodySml;
      font-style: italic;
      margin: 0;
    }
  }
}
