@use '../constants' as *;

.about-me-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

  .info {
    padding: 20px 40px;
    max-width: 1000px;
    justify-content: center;

    p {
      font-size: $body;
      white-space: pre-line;
      text-align: center;
      line-height: 150%;
      margin: 0;

      &.name {
        font-size: $title;
      }
    }
  }

  .cards-group {
    display: flex;
    flex: 1;
    margin-top: 40px;
    width: 100%;
    gap: 20px;
    padding: 0 25px;
    max-width: 1250px;
  }

  @media #{$tablet} {
    .info {
      padding: 25px;

      p {
        &.name {
          font-size: $body;
        }
      }
    }

    .cards-group {
      flex-direction: column;
      max-width: 500px;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    border: $btnHover solid 1px;
    border-radius: 10px;
    text-align: center;
    padding: 0px 20px 20px 20px;
    line-height: 150%;
    transition: all 0.3s;

    &:hover {
      cursor: default;
      border-color: $highlightCyan;
      background-color: $btnHover;
      transform: translateY(-10px);
    }

    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px 0;

      h2 {
        display: flex;
        font-size: $body;
        font-weight: normal;
        margin: 0;
        text-align: left;
      }
      .icon {
        display: flex;
        justify-self: flex-end;
        margin-right: 20px;

        svg {
          color: $highlightPink;
          font-size: $subheaderBig;
        }
      }
    }

    &-description {
      display: flex;
      align-items: center;

      p {
        font-size: $body;
        margin: 0;
        white-space: pre-line;
      }

      @media #{$mobile} {
        p {
          font-size: $bodySml;
        }
      }
    }
  }
}
