// COLOURS
$txtSecondary: #b3b3e2; // light purple
$highlightPink: #F2932F; // orange
$highlightCyan: #0ceef4; // blue
$bgBase: #1e1e3a; // base purple
$bgDarker: #191931; // darker purple
$bgLighter: #28284b; // lighter purple
$bgNavbar: #313166; // lightest purple of navbar
$btnHover: #2b5264; // dark blue
$error: rgb(255, 109, 109); // success feedback
$success: lightgreen; // error feedback

// FONT SIZES
$bodySml: 14px;
$body: 16px;
$title: 19px;

$headerBig: 30px;
$subheaderBig: 25px;

$headerSml: 21px;
$subheaderSml: 18px;

// WIDTHS
$navbarWidth: 160px;

// SCREEN SIZES
$mobile: 'screen and (max-width: 640px), screen and (max-height: 500px)';
$mobilePortraitOnly: 'screen and (max-width: 640px)';
$tablet: 'screen and (max-width: 900px)';
$laptop: 'screen and (max-width: 1200px)';
