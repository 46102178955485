@use 'constants' as *;

:root {
  background-color: $bgBase;
  scroll-behavior: smooth;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  font-family: Roboto, 'Open Sans', sans-serif;
}

section,
footer {
  color: white;
  margin-right: $navbarWidth;

  @media #{$mobile} {
    margin-right: 0;
  }
}

header {
  // background-color: $bgLighter;
  display: flex;
  align-items: center;
  height: 90px;
  padding-left: 120px;
  padding-top: 40px;

  h1 {
    margin: 0;
    font-size: $headerBig;
  }

  @media #{$tablet} {
    padding-left: 0;
    justify-content: center;
  }

  @media #{$mobile} {
    height: 80px;
    justify-content: center;
    padding: 0;

    h1 {
      font-size: $headerSml;
    }
  }
}

// Text highlights
.txtHighlightPink {
  color: $highlightPink;
}

.txtHighlightCyan {
  color: $highlightCyan;
}

// Background text
.txtFaded {
  font-family: monospace;
  opacity: 0.2;
  cursor: default;
}

// Blinking effect
.blinking {
  &.visible {
    opacity: 1;
  }

  &.hidden {
    opacity: 0;
  }
}

// Animated background text
.bg-text {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 30px;

  p {
    font-size: 1.5vw;
    margin: 0;
  }

  @media #{$laptop} {
    justify-content: center;
    p {
      font-size: 20px;
    }
  }
}

// Scrollbar container
::-webkit-scrollbar {
  width: 6px;
}

// Scrollbar track
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

// Scrollbar thumb
::-webkit-scrollbar-thumb {
  background: $bgNavbar;
  border-radius: 10px;
}
