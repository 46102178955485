@use '../constants' as *;

.home-container {
  display: flex;
  flex-direction: column;
  height: 100vh;

  @media #{$mobile} {
    height: 825px;
  }

  .top-container {
    display: flex;
    flex: 1;

    .intro-container {
      display: flex;
      flex: 1.75;
      flex-direction: column;
      justify-content: center;
      background-color: $bgDarker;
      text-align: center;
      padding: 20px;

      h1 {
        font-weight: normal;
        font-size: 38px;
        margin: 10px 0;
      }

      p {
        font-size: 28px;

        &.titles {
          line-height: 1.6em;
        }
      }
    }

    .bg-text-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      background-color: #222242;

      p {
        font-size: 2.5vw;
        margin: 50px 0 0 0;
      }

      .img-container {
        display: flex;
        overflow: hidden;

        img {
          object-fit: contain;
          width: 100%;
          max-height: 650px;
        }
      }
    }

    @media #{$laptop} {
      .intro-container {
        justify-content: flex-end;

        h1 {
          font-size: 30px;
        }
      }
      .bg-text-container {
        display: none;
      }
    }

    @media #{$mobile} {
      flex: 2;
      background-color: $bgDarker;
      .intro-container {
        padding: 0 20px;

        h1 {
          font-size: 24px;
          margin: 15px 0;
        }

        p {
          font-size: 20px;
          margin: 0 0 15px 0;

          &.titles {
            line-height: 1.6em;
          }
        }
      }
    }
  }

  .drawing-tablet {
    display: none;
    flex: 1;
    align-items: flex-start;
    justify-content: center;
    background-color: $bgDarker;

    img {
      object-fit: contain;
      width: 275px;
      height: 275px;
    }

    @media #{$laptop} {
      display: flex;
    }

    @media #{$mobile} {
      display: none;
    }
  }

  .drawing-mobile {
    display: none;
    flex: 1.5;
    align-items: center;
    justify-content: center;
    background-color: $bgDarker;

    img {
      object-fit: contain;
      width: 200px;
      height: 200px;
    }

    @media #{$mobile} {
      display: flex;
    }
  }

  .bottom-container {
    display: flex;
    flex-direction: column;
    flex: 0.4;
    background-color: $bgLighter;
    align-items: center;
    justify-content: center;

    .button-group {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      width: 350px;
      align-content: center;
      justify-content: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 35px;
        font-size: $body;
        font-weight: bold;
        background: none;
        border: none;
        border-radius: 6px;
        color: $bgBase;
        background-color: $highlightCyan;
        margin: 8px 10px;
        outline: transparent 1px solid;

        transition: all 0.25s linear;

        &:hover {
          cursor: pointer;
          color: $highlightPink;
          outline: $highlightCyan 1px solid;
          outline-offset: 2.5px;
        }

        svg {
          margin-right: 8px;
        }
        
        &.disabled {
          pointer-events: none;
          opacity: 0.5;
          background-color: $bgBase;
        }
      }
    }

    .mouse-icon {
      display: flex;
      color: white;
      font-size: 32px;
      margin-bottom: 20px;
      transition: all 0.25s linear;
      padding: 5px 0;

      &:hover {
        cursor: pointer;
        background-color: $highlightPink;
        border-radius: 35%;
      }
    }

    @media #{$laptop} {
      flex: 0.6;
    }

    @media #{$mobile} {
      flex: 1;
      background-color: $bgDarker;

      .button-group {
        button {
          font-size: 14px;
          width: 120px;
          height: 30px;
        }
      }
    }
  }

  .bg-text-container-mobile {
    display: none;
    width: 100%;
    justify-content: flex-end;
    padding: 0 15px;
    font-size: 20px;
    background-color: $bgDarker;

    @media #{$mobile} {
      display: flex;
    }
  }
}
